/* SPDX-License-Identifier: LicenseRef-Fortinet */
'use strict';
const neutrinoTheming = require('@neutrino/theming');

function init() {

    const themeMap = new Map([
        ['jade', neutrinoTheming.jadeTheme],
        ['neutrino', neutrinoTheming.neutrinoTheme],
        ['mariner', neutrinoTheming.marinerTheme],
        ['graphite', neutrinoTheming.graphiteTheme],
        ['melongene', neutrinoTheming.melongeneTheme],
        ['retro', neutrinoTheming.fortiosV3RetroTheme],
        ['dark-matter', neutrinoTheming.darkMatterTheme],
        ['onyx', neutrinoTheming.onyxTheme],
        ['eclipse', neutrinoTheming.eclipseTheme]
    ]);
    const themingManager = new neutrinoTheming.ThemingManager(document, window);
    const themeName = window.NEUTRINO_THEME || 'neutrino';

    themingManager.setActiveTheme(themeMap.get(themeName));
}

if (document.readyState !== 'loading') {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init);
}
